import React from 'react';
import LeftBar from './leftBar';
import BannerWrapper, {
  ContentWrapper,
  TextArea,
  ImageArea,
} from './bannerSection.style';

import BannerImage from './banner-image.jpg';

const BannerSection = () => {
  return (
    <BannerWrapper>
      <LeftBar text="SCROLL DOWN" offset={81} sectionId="#feature" />
      <ContentWrapper>
        <TextArea>
          <h2>
            <small>Why do we believe the Greenway plans are in direct conflict with the Lammas Lands Management Plan?</small>
          </h2>
          <p>
            In response to a question posed to us from <strong>Councillor Richard Ashworth</strong> of <strong>Godalming town council</strong>. We have set out
            the following deep dive into to why we believe the current Greenway proposals (to build infrastructure across the Lammas Lands) is <strong>in direct and serious conflict with the 10 year management plan.</strong> The plan is currently in it's 4th year.
          </p>

          <a className="learn__more-btn" href="/management-plan-2017.pdf" target="_blank">
            <span className="hyphen" />
            <span className="btn_text">View the full plan</span>
          </a>
        </TextArea>
        <ImageArea style={{ backgroundImage: `url(${BannerImage})` }} />
      </ContentWrapper>
    </BannerWrapper>
  );
};

export default BannerSection;
