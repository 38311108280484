import React from 'react';
import Container from 'common/components/UI/Container';
import { Link } from 'gatsby';
import SectionWrapper from './workSection.style';

const WorkSection = () => {
  return (
    <SectionWrapper id="work">
      <Container width="1260px">
        <p>
          <strong>Note: If you are coming to this page fresh, then we recommend reading our <Link to="/godalming-greenway">wider issues with the Lammas Lands</Link> segments of the Greenway plans before reading this as this goes quite deep into published policy.</strong></p>
          <p>
            The management plan for the Lammas Lands was undertaken in 2017 and seeks to ensure that the Lammas Lands continue to provide a valuable flood relief, recreational, wildlife, landscape and historical feature within Godalming.
          </p>
        <h2>Setting the Scene</h2>
        <p>
          <strong>The Lammas Lands are recognised as a Site of Nature Conservation Importance (SINC)</strong>. Although a non-statutory
          designation, SINCs are afforded a high level of protection within the planning system. Development that negatively
          impacts on a SINC may only be permitted in exceptional circumstances where mitigation can be proven
        </p>
        <p>
          <strong>The Lammas Lands support the habitats of principle importance</strong> (previously known as BAP priority habitats)
          Coastal and Floodplain Grazing Marsh. This designation is used to guide decision-makers such as public bodies,
          including local and regional authorities, in implementing their duty under section 40 of the NERC Act, to have
          regard to the conservation of biodiversity in England when carrying out their normal functions.
        </p>
        <p>
          <strong>A total of 108 species of flowering plants</strong> were recorded from the site in 1994, and 227 invertebrates,
          were recorded from the site in 2001 Six amber and twelve red BCC listed bird species have been recorded
          and the site has considerable potential to support additional numbers and species of bird. Otters have been
          spotted In hell ditch and roe deer in the meadows.
        </p>
        <p>
          <strong>As registered common land</strong>, the Lammas Lands have right of access on foot, including walking, running,
          wildlife watching and dog walking. Dogs must been kept on a lead no more than 2 metres long on open access
          land between 1 March and 31 July (to protect ground-nesting birds) and at all times around livestock (although
          this is often unenforced provided dogs are under control).
        </p>
        <p>
          <strong>A Countryside Stewardship Scheme</strong> was entered into in 2000 with the aim of instigating appropriate cattle
          grazing to increase the floristic diversity, together with the restoration of old ditch lines and creation of
          a shallow pond and scrape
        </p>
        <p>
          <strong>The Lammas Lands fall within the area covered by the Wey Landscape Partnership</strong>. The aim of this catchment
          plan is for the rivers and groundwater in the River Wey catchment to be healthy for the benefit of wildlife,
          local residents, businesses, tourists and anglers. A copy of the catchment plan <a href="/wlp-catchment-plan_sert_-draft-v3.pdf" target="_blank">can be found here</a>.
        </p>
        <p>
          <strong>The Lammas Lands are recognised as an Area of Strategic Visual Importance (ASVI)</strong>, which is a local landscape
          designation which was developed as part of the Local Plan Brief of 1981 and the Local Plan 1984. As a local
          designation, the areas designated as ASVI are subject to review and change by the Council as part of the
          Local Plan process. The importance of the designation is to protect areas of land which are important because
          of the role they play in establishing the character of a locality and preventing coalescence of developed areas.
          The areas should be predominantly open land and may therefore be vulnerable to development due to their location
          adjacent to developed areas. They are often small areas and may also penetrate into the urban areas like a green ‘lung’. A copy of the Lammas Land ASVI designation <a href="/ASVI_Topic_Paper.pdf" target="_blank">can be found here.</a>
        </p>
        <p>
          <strong>Waverley council have been awarded the Green flag award</strong> for their management of the meadows, this can be seen flying on the flag pole outside the library, a part of our collective town pride. The Green Flag Award
          scheme, managed by Keep Britain Tidy under licence from the Ministry of Housing, Communities and Local Government,
          recognises and rewards well-managed parks and green spaces, setting the benchmark for the management of
          recreational outdoor spaces across the UK and around the world. The award specifically references the council(s) hard work on the Management Plan.
        </p>

        <h2>At Odds with the Prescribed Objectives</h2>

        <p>
          Below we have detailed which of the management plan objectives the current proposals are in direct opposition to, if you want to see the extensive list, then refer to <a href="/management-plan-2017.pdf" target="_blank">Management Plan</a> itself. This
          is not meant as a complete list, just the most obvious points.
        </p>

        <h3>Objective 1: To carry out management and maintenance of the Lammas Lands without restricting their important function as a flood plain to the River Wey</h3>

        <ul>
          <li>
            We have detailed this elsewhere. However at the council(s) planners own admission this will have a marginal
            impact on floodplain storage. The <a target="_blank" href="https://www.gov.uk/guidance/flood-risk-and-coastal-change#flood-zone-and-flood-risk-tables">environment agency specifically sets out</a> that any development on a floodplain
            should have no net loss of floodplain storage. We do not see how a 4m wide boardwalk or path is not going to
            result in any kind of reduction in floodplain storage, with the overall weight measured of either the boardwalk or path in the tons. This especially coupled with the construction, groundwork and disturbance to the environment to support either
            of the meadow option. Poor soil quality (a series of sandstones and clays) and the reality of construction on functional floodplains, will mean that either the boardwalk or
            path will have to be significantly reinforced. For further information, read our more in depth analysis on <Link to="/our-response/flood-policy/">why we believe this to fall short of Environment Agency guidelines here.</Link>
          </li>
          <li>
            This objective specifically states that nothing is done to prevent or obstruct the free flow of water across
            the meadows, and that the open nature of the site is maintained. Although a boardwalk or path will have "marginal" effect on the free
            flow of water, it will effect storage and will also infringe on the open rural nature of the meadows. Taking it towards its first steps as just another park, not the "green lung" it should be, under its ASVI designation.
          </li>
        </ul>

        <h3>Objective 2: To manage Hell Ditch for the benefit of wildlife and flood relief</h3>

        <ul>
          <li>By building a boardwalk across Overgone meadow, the only vehicle access (by the chiropractor) to the meadow will be blocked. This will prevent proper access in order to be able to service Hell Ditch.</li>
        </ul>

        <h3>Objective 4: Maintain the dry grassland communities by cutting and/or grazing, having regard to the use of the site by the public</h3>

        <ul>
          <li>
            The building of a boardwalk will cut off the only vehicle access to Overgone meadow. This will prevent both grazing and any maintenance access,
            meaning that much of the work to get the meadow to it’s current state will slowly be undone. <a href="https://www.surreywildlifetrust.org/" target="_blank">Surrey Wildlife
            Trust</a> have previously been very vocal about grazing being continued for this exact reason, we have not confirmed why they are not currently livid about these plans.
          </li>
          <li>
            Alongside the vehicle access being removed we are very interested whether this will fall under the remit of parks to keep clean,
            as increased refuse will find it's way onto the Lammas Lands due to foot and cycle traffic where there was previously none. It should be noted that the length of this
            entrance is very important due to the need for farmers to be able to unload cattle. We do not believe there to be anywhere else in the meadow that this access could go.
          </li>
          <li>
            Pushing both foot and cycle traffic through the Lammas Lands will mean the grazing animals will stay further away and the entire meadow will not be serviced by them.
          </li>
          <li>
            As can be seen from the <a href="/visitor-survey-2016.pdf" target="_blank">2016 visitor survey</a>,
            91.7% of people valued the continued cattle grazing of this land. Pedestrian and cycle traffic will have an
            effect on livestock and will push them away from maintaining the entire meadow, as recommended by the plan.
          </li>
        </ul>

        <h3>Objective 5: To provide suitable habitat and disturbance-free conditions for re-colonisation by a wetland breeding bird assemblage and to enhance existing conditions for wintering birds. </h3>

        <ul>
          <li>
            By pushing much of the foot traffic and bicycle traffic onto the lammas lands,
            you are not creating disturbance free conditions for wetland birds. The current use of the site, as noted by the
            report for recreation causes some disturbance to potential breeding and wintering birds. We should not be
            adding to the disturbance that has already been recognised by the plan, which is already recognised as an issue under current footfall.
          </li>
          <li>
            A member of the British Trust for Ornithology had the following to say about the proposals,
            <br /><br />
            <span style={{ fontWeight: 600, fontStyle: 'italic' }}>"I take part in the British Trust for Ornithology wetland bird survey on the Lammas lands on a monthly basis. The proposals to build a 4m road across this precious wildlife preserve is appalling."</span>
          </li>
        </ul>

        <h3>Objective 7: To maintain and create favourable conditions for invertebrates associated with wet meadows, swamps and dead wood</h3>

        <ul>
          <li>
            Any lighting required to make this path usable throughout the year will have severe effect on the 227 invertebrates recorded within the Lammas Lands. Some of which are considered at very high risk. We highly recommend
            the <a href="https://cdn.buglife.org.uk/2019/08/A-Review-of-the-Impact-of-Artificial-Light-on-Invertebrates-docx_0.pdf" target="_blank">following report if you doubt the effect of artificial light on invertebrates</a>.
            As mentioned in the following point, we believe that lighting would be a necessity under section 17 of Crime and Disorder Act (1998) and The Highways Act (1980).
          </li>
        </ul>

        <h3>Objective 8: Maintain the facility for roosting bats and encourage use of the site by otters</h3>

        <ul>
          <li>
            As noted by the report, otters are inherently shy of humans. Pushing more traffic through the Lammas Lands
            will only degrade this. The same applies to bats, which can be seen nightly across the Lammas Lands.
            Pedestrian and cycle traffic will only result in both being pushed from their natural environment. We specifically draw attention to the Conservation of Habitats and Species Regulations (2010) and Section 40 of the Natural Environment and Rural Communities Act (2006).
          </li>
          <li>
            Lighting will be required by necessity to ensure the safety of the boardwalk and path in the winter months, this will
            further disturb the nocturnal ecosystem. We specifically draw these conclusions from section 17 of Crime and Disorder Act (1998) and The Highways Act (1980).
          </li>
        </ul>

        <h3>Objective 10: Maintain all the historic features of the site and encourage collection of further data on history and archaeology</h3>

        <ul>
          <li>
            There is a recommendation to retain the remains of former ditch and sluice systems, these features reflecting past management as flood or water meadows and being a valuable reminder of the
            history of the site.

            The boardwalk would cross over the remains of former ditch and sluice systems, with required construction disturbing or destroying them.
          </li>
        </ul>

        <h3>Objective 11: Maintain the views across the Lammas Lands and from within the meadows to the features of the town.</h3>

        <ul>
          <li>
            To quote the report direclty, "Residential properties look down on the meadows from the north and south and there are views of local churches,
            the river Wey and the town from the meadows". By placing a boardwalk through the meadows, you are not maintaining
            the rural feel of the town that the report recommends, which is also recognised as a key cornerstone of the town by the local plan.
          </li>
          <li>
            Additionally to this, the Godalming and Farncombe Neighbourhood Plan (2019) engagement process emphasises the
            importance of protecting valued landmark views. Protecting the historic view panoramic view from Chalk Road/Bridge
            Road towards the Parish Church of St Peter and St Paul.
          </li>
        </ul>

        <h3>Objective 12: To continue to promote the Lammas Lands as a safe, enjoyable and educational facility for the local community and visitors to the area</h3>

        <ul>
          <li>
            This objective recommends that Cyclists should continue to be confined to the tow path alongside the Wey for all of the
            ecological reasons set out in the report, which the Lammas Lands segment of the Greenway proposals specifically
            ignores.
          </li>
          <li>
            It also emphasises that users should be encouraged to minimise disturbance to the wetland features of the site,
            even including appropriate temporary signage. Which the Lammas Lands segment of the Greenway proposals ignore.
          </li>
        </ul>

        <h3>Objective 13: Carry out all statutory and other duties as site manager</h3>

        <ul>
          <li>
            This objective emphasises the need to continue to take steps to prevent undesirable activities on the common
            which could pose a hazard to the wildlife interest or the visiting public. Which we do not believe the current Lammas Lands proposals to do
            and is also highlighted in other sections of the objectives breaches (in providing disturbance free conditions, etc...).
          </li>
          <li>
            As the landing site for the bridge over the Wey does actually flood, most recently in 2020, this would render this route unusable and would put it in breach
            of the Environment Agency guidelines for infrastructure of its type on a floodplain, posing danger to members of the public and not being able to be used during flood.
          </li>
        </ul>

        <h2>In Conclusion</h2>

        <p>Under our count, 10 of 14 (71%) of the objectives set out by the management plan are put in direct conflict with the Greenway proposals, to build infrastructure on the Lammas Lands. </p>

        <p>
          Any recommendation to the Secretary of State under section 38 of the Commons Act (2006) to be able to enact this plan will require the
          council to be working to pass several key tests, one of which being for this proposal to be in the best interest
          of the site(s) actual current users. Which we regrettably do not believe there is evidence to suggest.
        </p>

        <p>
          We draw the council(s) and councillor(s) attention to the <a href="/visitor-survey-2016.pdf" target="_blank">2016 visitor survey</a>,
          This survey consulted 219 actual users of the Lammas Lands. The overall
          finding was that 87.7% of visitors valued floodplain management, 87.7% valued environment management and a further 91.7%
          valuing cattle grazing. We do not believe the current plans put any value to the vision of future management
          that was vocalised by the actual users of the meadows in 2016.
        </p>

        <p>
          We also draw the council(s) and councillor(s) attention to the no net loss calculation and its associated policy for biodiversity.
          We question whether the net loss will be justifiable to these delicate ecosystems in justifying the end goal.
        </p>

        <p>We highly recommend that you check out the <Link to="/resources">resources section</Link> of our site, this includes documentation sourced from all authorities and experts involved in the formation of these opinions.</p>

        <h2>Bonus Points: Restrictive Covenants</h2>

        <p>Surrey County Council appear to be ignoring <a href="/RegisterSY644883.pdf" target="_blank">the restrictive covenants</a> in place at Land Registry in their plans for a boardwalk on Overgone Meadow. The key covenant states that,</p>
        <p style={{ fontWeight: 600, paddingLeft: '1.5em' }}>"not at any time hereafter either to change the use from that of agriculture or to take any action which would encourage public use of the said property (edged red excluding the 4.35 acres between Chalk Road and Hells Ditch)."</p>
        <p>These restrictive covenants, in the words of a member of the family that put them in place, were put there to ensure that they remain as farming, grazing and wildlife land. They are very keen for it to stay that way.</p>

        <h2>Bonus Points: The National Trust</h2>

        <p>The <a href="https://www.nationaltrust.org.uk/" target="_blank">National Trust</a> have set out the following points, these highlight what they will directly find issue with and fight against with regards to the Lammas Lands,</p>

        <ul>
          <li>The Trust will object to development proposals which involve the Lammas Lands or other
            areas of undeveloped countryside adjacent to the Navigations (or new
            development within the visual setting of the Navigations) </li>
          <li>The Trust will resist proposals for development which are likely to result in an increased
            risk of flooding or an adverse impact on the water table</li>
          <li>The Trust will resist the building of structures, whether temporary or permanent, which
            could de-stabilise the banks of the Navigations </li>
        </ul>

        <p><span style={{ fontStyle: "italic", fontWeight: 600 }}>"The Trust is currently giving careful consideration to the Greenway proposals and are assessing the implications for the Wey Navigation and public use of the towpath. Our intention is to liaise closely with Surrey County Council / Waverley BC and to make a consultation response before the deadline on 21 November."</span> - Tristan Brown (General Manger - National Trust)</p>

      </Container>
    </SectionWrapper>
  );
};

export default WorkSection;
